.info {
  background-color: white;
}

.form {
  min-width: 60%;

  .field {
    margin-bottom: 1.5em;
  }
}
