.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;

  .icon {
    margin-right: 0.5em;
  }
}
