.card {
  cursor: pointer;
  background: #ffffff;
  width: 100%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;

  &:hover {
    box-shadow: 0 6px 20px 0 rgba(0,0,0,.35);
  }

  .cover {
    height: 160px;
    min-height: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    .icons {
      color: white;
      z-index: 999;
      position: absolute;
      top: 0.8em;
      right: 0.8em;

      i {
        color: #ffffff;
        text-shadow: 1px 1px 1px rgba(0,0,0,.50);
      }
    }

    img {
      z-index: 999;
      max-width: 80%;
      max-height: 100px;
    }
  }

  .card-content {
    .title {
      font-size: 1.3em;
      font-weight: 500;
      margin-bottom: 1em;
    }

    .sub-title {
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
